import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{fontSize: 26}}>NOT FOUND</h1>
    <p style={{fontSize: 26}}>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <a href="https://restbar13.com/" style={{fontSize: 26}}>Back to Homepage</a>
  </Layout>
)

export default NotFoundPage
